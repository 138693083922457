/** @format */

import { Button } from '@mui/material';
import React from 'react';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const NewButton = ({ title, bgColor, onClick, setWidth }) => {
  return (
    <Button
      variant='contained'
      onClick={onClick}
      sx={{
        width: { setWidth }, // Responsive width
        border: '1px solid #f0f0f0',
        color: '#f0f0f0',
        backgroundColor: bgColor,
        borderRadius: '20px',
        padding: '10px 20px', // Use padding for responsive height
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        fontSize: { xs: '0.5rem', sm: '0.7rem', md: '0.8rem' }, // Responsive font size
      }}>
      {title}
      <ArrowOutwardIcon />
    </Button>
  );
};

export default NewButton;
