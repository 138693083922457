/** @format */

import React from 'react';
import { useState } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/NavBar';
import backgroundVideo from '../Assets/BGVideo.mp4';
import NewButton from '../Components/NewButton';
import bottomImg from '../Assets/bottomImg.png';
import earth from '../Assets/map.png';
import ContactBtn from '../Components/ContactBtn';
import ReactPlayer from 'react-player';
import VideoContent1 from '../Assets/videoContent1.mp4';
import Footer from '../Components/Footer';
import imghome1 from '../Assets/imghome2.webp';
import imghome2 from '../Assets/imghome1.webp';
import imghome3 from '../Assets/imghome3.webp';

const Home = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleServices = () => {
    window.scrollTo(0, 0); // Scrolls to the top
    navigate('/metrithaServices'); // Navigates to the /metrithaServices page
  };
  const handleAssociates = () => {
    window.scrollTo(0, 0); // Scrolls to the top
    navigate('/metrithaServices'); // Navigates to the /metrithaServices page
  };

  return (
    <>
      <Box sx={{ position: 'relative', height: '100vh', width: '100%' }}>
        {/* Background Video */}
        <video
          autoPlay
          loop
          muted
          style={{
            // position: 'absolute',
            position: 'relative',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
            zIndex: -1, // Send video to background
          }}>
          <source
            src={backgroundVideo}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            mt: 2,
          }}>
          <Navbar mainHeading='Admin Dashboard' />

          <Grid
            container
            spacing={2}
            height={'60%'}>
            <Grid
              item
              xs={0}
              sm={3}
              md={3}
              lg={3}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ textAlign: 'center' }}>
              <Typography
                variant='h1'
                sx={{
                  fontFamily: 'Poppins',
                  color: '#fff',
                  fontWeight: 'thin',
                  fontSize: {
                    xs: '1.5rem',
                    sm: '2.5rem',
                    md: '3rem',
                    lg: '4rem',
                  },
                  letterSpacing: '1px',
                  lineHeight: '1.5',
                }}>
                We Are Your Key financial Advisors.
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
            }}>
            <NewButton
              title='Get Started'
              bgColor='#331D48'
              onClick={undefined}
            />
          </Grid> */}
        </Box>
      </Box>
      <Grid
        container
        // spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#331D48',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },
          pt: 5,
          paddingX: { xs: '1rem', sm: '2rem', md: '2.5rem', lg: '5rem' },
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}>
          <Typography
            variant='subtitle'
            sx={{
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              fontSize: {
                xs: '0.8rem',
                sm: '0.8rem',
                md: '0.8rem',
                lg: '1rem',
                xl: '1.2rem',
              },
              fontWeight: '300',
              letterSpacing: '1px',
            }}>
            {/* Metritha investment and business banking was created to do business
            and connect families, and their operating businesses in the region,
            and also between the region and the world. It differs from the
            classic investment banking model, by facilitating business that
            requires a tailor-made approach, enabling the execution of
            entrepreneurial family business initiatives. */}
            {localStorage.getItem('content1')
              ? localStorage.getItem('content1')
              : 'Metritha investment and business banking was created to do business and connect families, and their operating businesses in the region,and also between the region and the world. It differs from the classic investment banking model, by facilitating business that requires a tailor-made approach, enabling the execution of entrepreneurial family business initiatives.'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          justifyContent={'center'}
          display={'flex'}>
          <img
            src={imghome1}
            alt='imghome1'
            style={{
              width: '85%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        // spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#331D48',

          flexDirection: {
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'row',
            lg: 'row',
          },
          // paddingLeft: '5%',
          pt: 5,
          // paddingX: 5,
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          justifyContent={'center'}
          display={'flex'}>
          <img
            src={imghome2}
            alt='imghome1'
            style={{
              width: '85%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          gap={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}>
          <Typography
            variant='subtitle'
            sx={{
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              fontSize: {
                xs: '0.8rem',
                sm: '0.8rem',
                md: '0.8rem',
                lg: '1rem',
                xl: '1.2rem',
              },
              fontWeight: '300',
              letterSpacing: '1px',
              paddingX: {
                xs: '0.75rem',
                sm: '1.2rem',
                md: '1rem',
                lg: '3rem',
              },
            }}>
            {/* Metritha owes its origin to Metritha Colombia and its founder Mr.
            Andres Moreno, who about 22 years ago, specialized in delivering
            investment banking services to Colombian business families and their
            operating companies. In order to better serve customers and support
            them regionally, an alliance was formed with companies that are part
            of the Prime Global network. This is how the firms Traust Accounting
            & Legal Firm, from Monterey - Mexico, and MS Santini Multifamily
            Office, from Sao Paulo - Brazil, became shareholders in this
            initiative. The firm's main offices are located in Miami, USA, a
            natural location to conduct business in the Americas. */}
            {localStorage.getItem('content2')
              ? localStorage.getItem('content2')
              : "Metritha owes its origin to Metritha Colombia and its founder Mr.Andres Moreno, who about 22 years ago, specialized in delivering investment banking services to Colombian business families and their operating companies. In order to better serve customers and support them regionally, an alliance was formed with companies that are part of the Prime Global network. This is how the firms Traust Accounting & Legal Firm, from Monterey - Mexico, and MS Santini Multi family Office, from Sao Paulo - Brazil, became shareholders in this initiative. The firm's main offices are located in Miami, USA, a natural location to conduct business in the Americas."}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        // spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#331D48',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },
          // paddingLeft: '5%',
          pt: 5,
          paddingX: { xs: '1rem', sm: '2rem', md: '1.7rem', lg: '3.2rem' },
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          gap={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}>
          <Typography
            variant='subtitle'
            sx={{
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              fontSize: {
                xs: '0.8rem',
                sm: '0.8rem',
                md: '0.8rem',
                lg: '1rem',
                xl: '1.2rem',
              },
              fontWeight: '300',
              letterSpacing: '1px',
            }}>
            {/* Two years ago, at a Prime Global event in Rio de Janeiro, Metritha
            was officially launched and the initiative was socialized among
            Prime Global members from different Latin American countries. In
            2024, we already have eleven local partners in region countries. Our
            local artners are Prime Global network companies from El Salvador,
            Dominican Republic, Aruba, Ecuador, Peru, Chile, Paraguay, Uruguay
            and Argentina, in addition to our shareholders in Mexico and Brazil.  
            Metritha brings together professionals with extensive knowledge in
            various areas of corporate finance, including M&A, sourcing, project
            and project finance structuring, negotiation and corporate
            restructuring. The result is personalized service for our clients
            delivered by senior and creative executives. */}
            {localStorage.getItem('content3')
              ? localStorage.getItem('content3')
              : 'Two years ago, at a Prime Global event in Rio de Janeiro, Metritha was officially launched and the initiative was socialized among Prime Global members from different Latin American countries. In 2024, we already have eleven local partners in region countries. Our local artners are Prime Global network companies from El Salvador, Dominican Republic, Aruba, Ecuador, Peru, Chile, Paraguay, Uruguay and Argentina, in addition to our shareholders in Mexico and Brazil. Metritha brings together professionals with extensive knowledge in various areas of corporate finance, including M&A, sourcing, project and project finance structuring, negotiation and corporate restructuring. The result is personalized service for our clients delivered by senior and creative executives.'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          justifyContent={'center'}
          display={'flex'}>
          <img
            src={imghome3}
            alt='imghome1'
            style={{
              width: '85%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        backgroundColor={'#331D48'}
        spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}>
          <Typography
            variant='h3'
            sx={{
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              letterSpacing: '2px',
              fontWeight: '300',
              mt: 10,
              paddingX: 2,
              fontSize: {
                xs: '1rem',
                sm: '1rem',
                md: '1.5rem',
                lg: '2.2rem',
              },
            }}>
            Metritha’s trusted partners and associates across various regions.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            mt: 1,
            mb: 1,
          }}>
          <img
            src={earth}
            alt='logo'
            style={{ maxWidth: '100%', maxHeight: '75%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}>
          <Typography
            variant='h3'
            sx={{
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              letterSpacing: '2px',
              fontWeight: '300',
              mt: {
                xs: 2,
                sm: 2,
                md: 4,
                lg: 6,
              },
              fontSize: {
                xs: '1rem',
                sm: '1rem',
                md: '1.5rem',
                lg: '2.2rem',
              },
            }}>
            Principles
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 2,
          }}>
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}>
            <ul
              style={{
                listStyle: 'none',
                padding: 5,
                textAlign: 'center',
                color: '#f0f0f0',
                fontFamily: 'Poppins',
                lineHeight: 2,
                textAlign: 'center',
                letterSpacing: '1px',
                fontWeight: '300',
              }}>
              <li>
                Focus on the client, with senior and exclusive service in
                operations.
              </li>
              <li>
                Emphasis on long-term relationships with clients, fostering a
                relationship of trust.
              </li>
              <li>Excellence in execution.</li>
              <li>
                Highly qualified professionals with proven experience in their
                respective fields of expertize.
              </li>
              <li>
                A lean team with an agile decision-making process, focused on
                results and long-term value creation.
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='center'
          alignItems='center'>
          <Grid
            item
            xs={12}
            display='flex'
            flexDirection={{
              xs: 'column', // Flex direction as 'column' on extra-small and small screens
              sm: 'column', // Flex direction as 'column' on small screens
              md: 'row', // Flex direction as 'row' on medium and larger screens
            }}
            justifyContent='center'
            alignItems='center'
            gap={2}>
            {/* First Button */}
            <NewButton
              title='Metritha Services'
              bgColor='#331D48'
              onClick={handleServices}
            />
            {/* Second Button */}
            <NewButton
              title='Metritha Network'
              bgColor='#331D48'
              onClick={handleAssociates}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        backgroundColor={'#331D48'}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            position: 'relative',
          }}>
          {/* Image */}
          <img
            src={bottomImg}
            alt='logo'
            style={{
              width: '80%',
              height: '80%',
              maxWidth: '100%',
            }}
          />

          {/* Overlay Content */}
          <Box
            sx={{
              position: 'absolute',
              top: '30%', // Adjust to center better on small screens
              left: '50%',
              transform: 'translate(-50%, -50%)', // Adjust position
              textAlign: 'center',
              color: '#fff', // Text color
              zIndex: 1, // Ensure text is above the image
              padding: { xs: '10px', sm: '20px' }, // Padding for mobile
              width: { xs: '100%', sm: '80%', md: '60%' }, // Responsive width
            }}>
            <Typography
              variant='h1'
              sx={{
                fontWeight: 'lighter',
                fontFamily: 'Poppins',
                color: '#f0f0f0',
                fontSize: { xs: '12px', sm: '20px', md: '25px', lg: '40px' },
                letterSpacing: { xs: '2px', sm: '3px', md: '5px' },
              }}>
              Interested in our portfolio?
            </Typography>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              top: { xs: '70%', sm: '65%', md: '60%' },
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              width: { xs: '80%', sm: '55%', md: '90%', lg: '100%' },
              // height: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
            }}>
            <ContactBtn
              open={open}
              handleClose={handleClose}
              setWidth={{ xs: '10%', sm: '10%', md: '50%', lg: '100%' }}
            />

            <Typography
              variant='body1'
              sx={{
                fontFamily: 'Poppins',
                color: '#f0f0f0',
                marginTop: '15px',
                display: {
                  xs: 'none',
                  sm: 'flex',
                  md: 'flex',
                  lg: 'flex',
                },
              }}>
              We will call you back in..
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Home;
