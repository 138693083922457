/** @format */

import React, { useState } from 'react';
import { Grid, Typography, Box, Button, Modal, TextField } from '@mui/material';
import { AccountCircle, Email, Message } from '@mui/icons-material';
import NewButton from './NewButton'; // Assuming NewButton is in the same directory

const ContactBtn = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Button that opens the modal */}
      <NewButton
        title='Click Me'
        bgColor='#9900ff'
        variant='contained'
        sx={{
          marginTop: '10px',
          width: { xs: '80%', sm: '60%', md: '30%' },
          fontSize: { xs: '0.5rem', sm: '0.7rem', md: '0.8rem' },
        }}
        onClick={handleOpen} // Pass handleOpen to the button
      />

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='contact-form-modal'>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '60%', md: '40%' },
            maxWidth: '80%',
            bgcolor: '#331D48',
            boxShadow: 24,
            p: { xs: 2, sm: 3, md: 4 },
            borderRadius: 2,
            // border: '1px solid #666',
          }}>
          <Typography
            variant='body1'
            component='body1'
            sx={{
              mb: 0,
              color: '#f0f0f0',
              fontWeight: '300',
              fontSize: '3rem',
              fontFamily: 'Poppins',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}>
            Let us know a little but more about you!
          </Typography>

          <Box
            component='form'
            noValidate
            sx={{
              p: 4,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
            }}>
            {/* Name Field */}
            <Grid
              container
              alignItems='center'
              spacing={2}
              sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}>
                <TextField
                  fullWidth
                  label='Full Name'
                  variant='standard'
                  InputLabelProps={{
                    style: { fontSize: '18px', color: '#666' },
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#666', // Color on focus
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#666', // Underline color on focus
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}>
                <TextField
                  fullWidth
                  label='Email'
                  variant='standard'
                  InputLabelProps={{
                    style: { fontSize: '18px', color: '#666' },
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#666', // Color on focus
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#666', // Underline color on focus
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* Email Field */}
            <Grid
              container
              alignItems='center'
              sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}>
                <TextField
                  fullWidth
                  label='Phone Number'
                  variant='standard'
                  InputLabelProps={{
                    style: { fontSize: '18px', color: '#666' },
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#666', // Color on focus
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#666', // Underline color on focus
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* Message Field */}
            <Grid
              container
              alignItems='center'
              sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}>
                <TextField
                  fullWidth
                  label='Anything in particular you are looking for? Let us know!'
                  variant='standard'
                  multiline
                  rows={4}
                  InputLabelProps={{
                    style: { fontSize: '18px', color: '#666' },
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#666', // Color on focus
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#666', // Underline color on focus
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 5,
              }}>
              <Button
                variant='contained'
                sx={{
                  width: '75%',
                  mb: 2,
                  backgroundColor: '#f0f0f0',
                  color: '#331D48',
                  borderRadius: '20px',
                }}
                size='small'
                type='submit'
                onClick={handleClose}>
                Send
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ContactBtn;
