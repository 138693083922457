/** @format */

import React, { useState } from 'react';
import Navbar from '../../Components/NavBar';
import { Box, Grid, Button } from '@mui/material';
import ContentForHome from './ContentForHome';

const AdminDashboard = () => {
  const [isHomeContent, setIsHomeContent] = useState(false);

  return (
    <Box
      style={{
        padding: '20px',
        backgroundColor: '#331D48',
        height: '100%',
      }}>
      <Navbar
        mainHeading='Admin Dashboard'
        isAdmin={true}
      />
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
          my: 4,
        }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button
            variant='contained'
            onClick={() => setIsHomeContent(true)}
            sx={{
              width: '80%',
              mb: 2,
              color: '#331D48',
              backgroundColor: '#f0f0f0',
              fontSize: '1.2rem',
              fontFamily: 'Poppins',
              fontWeight: 'bold',
            }}>
            Content
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button
            variant='contained'
            sx={{
              width: '80%',
              mb: 2,
              color: '#331D48',
              backgroundColor: '#f0f0f0',
              fontSize: '1.2rem',
              fontFamily: 'Poppins',
              fontWeight: 'bold',
            }}>
            Images
          </Button>
        </Grid>
      </Grid>

      {isHomeContent && <ContentForHome />}
    </Box>
  );
};

export default AdminDashboard;
