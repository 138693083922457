/** @format */

import React, { useEffect, useState } from 'react';
import { Box, TextField, Grid, Typography, Button } from '@mui/material';

const ContentForHome = () => {
  const [homecontent1, setHomeContent1] = useState(
    localStorage.getItem('content1') || ''
  );
  const [homecontent2, setHomeContent2] = useState(
    localStorage.getItem('content2') || ''
  );
  const [homecontent3, setHomeContent3] = useState(
    localStorage.getItem('content3') || ''
  );

  const handleUpdateHomeContent = () => {
    localStorage.setItem('content1', homecontent1);
    localStorage.setItem('content2', homecontent2);
    localStorage.setItem('content3', homecontent3);

    localStorage.setItem('content1', homecontent1);
    localStorage.setItem('content2', homecontent2);
    localStorage.setItem('content3', homecontent3);

    alert('Content updated and saved to localStorage!');
    console.log('Content saved to localStorage');
  };

  useEffect(() => {
    console.log('Component mounted');
    console.log('Initial content from localStorage:');
    console.log('Content 1:', localStorage.getItem('content1'));
    console.log('Content 2:', localStorage.getItem('content2'));
    console.log('Content 3:', localStorage.getItem('content3'));
  }, []);

  const handleChangeHomeContent1 = (e) => {
    setHomeContent1(e.target.value);
    console.log('Content 1 changed:', e.target.value); // Log content1 changes
  };

  const handleChangeHomeContent2 = (e) => {
    setHomeContent2(e.target.value);
    console.log('Content 2 changed:', e.target.value); // Log content2 changes
  };

  const handleChangeHomeContent3 = (e) => {
    setHomeContent3(e.target.value);
    console.log('Content 3 changed:', e.target.value); // Log content3 changes
  };

  return (
    <Box
      style={{
        padding: '20px',
        height: '100Vh',
      }}>
      <Typography
        variant='h6'
        component='h1'
        sx={{ color: 'white' }}>
        Content for Home page
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
          my: 4,
        }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{ width: '100%' }}>
          <TextField
            id='outlined-multiline-static'
            label='content no. 1'
            value={homecontent1}
            onChange={handleChangeHomeContent1}
            multiline
            rows={12}
            InputProps={{
              style: {
                color: 'white',
                fontFamily: 'poppins',
                fontSize: '16px',
                textAlign: 'start',
                letterSpacing: '1px',
              },
            }}
            InputLabelProps={{
              style: {
                color: 'white',
                fontSize: '18px',
                fontFamily: 'poppins',
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // Border color
                },
                '&:hover fieldset': {
                  borderColor: 'white', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white', // Border color when focused
                },
              },
              width: '100%',
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{ width: '100%' }}>
          <TextField
            id='outlined-multiline-static'
            label='content no. 2'
            value={homecontent2}
            onChange={handleChangeHomeContent2}
            multiline
            rows={12}
            InputProps={{
              style: {
                color: 'white',
                fontFamily: 'poppins',
                fontSize: '16px',
                textAlign: 'start',
                letterSpacing: '1px',
              },
            }}
            InputLabelProps={{
              style: {
                color: 'white',
                fontSize: '18px',
                fontFamily: 'poppins',
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // Border color
                },
                '&:hover fieldset': {
                  borderColor: 'white', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white', // Border color when focused
                },
              },
              width: '100%',
              maxWidth: '600px',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{ width: '100%' }}>
          <TextField
            id='outlined-multiline-static'
            label='content no. 3'
            value={homecontent3}
            onChange={handleChangeHomeContent3}
            multiline
            rows={12}
            InputProps={{
              style: {
                color: 'white',
                fontFamily: 'poppins',
                fontSize: '16px',
                textAlign: 'start',
                letterSpacing: '1px',
              },
            }}
            InputLabelProps={{
              style: {
                color: 'white',
                fontSize: '18px',
                fontFamily: 'poppins',
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // Border color
                },
                '&:hover fieldset': {
                  borderColor: 'white', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white', // Border color when focused
                },
              },
              width: '100%',
              maxWidth: '600px',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Button
          onClick={handleUpdateHomeContent}
          sx={{
            backgroundColor: 'white',
            color: '#9900ff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography
            variant='h6'
            component='h1'>
            Update content
          </Typography>
        </Button>
      </Grid>
    </Box>
  );
};

export default ContentForHome;
