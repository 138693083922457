/** @format */

import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import logo from '../../Assets/logo.png';

const AdminLogin = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'admin' && password === 'admin') {
      onLogin();
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#666',
        py: 4,
        px: 4,
      }}>
      <Typography
        variant='h4'
        component='h1'
        sx={{
          color: 'white',
          mb: 4,
          fontWeight: 'bold',
          textAlign: 'center',
          fontFamily: 'poppins',
        }}>
        Admin for Metritha
      </Typography>

      <Card
        sx={{
          maxWidth: 600,
          width: '90%',
          py: 8,
          px: 4,
          textAlign: 'center',
          backgroundColor: '#331D48',
          borderRadius: 4,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        }}>
        <CardContent>
          <img
            src={logo}
            alt='Logo'
            style={{ maxWidth: '100%', height: 'auto', marginBottom: 20 }}
          />
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label='Username'
              variant='standard'
              margin='normal'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                style: {
                  color: 'white', // Text color
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white', // Label color
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white', // Border color when focused
                  },
                },
              }}
            />

            <TextField
              fullWidth
              label='Password'
              type='password'
              variant='standard'
              margin='normal'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                style: {
                  color: 'white', // Text color
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white', // Label color
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white', // Border color when focused
                  },
                },
              }}
            />
            <Button
              type='submit'
              variant='contained'
              sx={{
                backgroundColor: 'purple',
                color: 'white',
                mt: 2,
                '&:hover': {
                  backgroundColor: 'darkviolet', // Darker on hover
                },
              }}
              fullWidth>
              Login
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdminLogin;
